


.video-card{
  /*  &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 1005;
        background-color: rgba(0, 0, 0, 100);
    }*/
}

.note-card {
    max-width: 348px;
    max-height: 348px;
}

.testimonial-card {
    min-height: 256px;
    box-shadow: 0px 0rem 1rem 2px rgb(0 0 0 / 15%) !important;

    .client-img {
        .profile {
            position: absolute;
            top: -1.2rem;
            width: 55px;
    height: 55px;
    border-radius: 100%;
    margin: auto;
    background-color: #f0d7da;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 10px 2px #f8c0c7
        }
    }

    p{
        font-size: 12px;
    }
}