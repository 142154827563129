.app-btn{
    font-family: Nunito;
    font-weight: bold;
    font-size: 15px;
    border-radius: 20px;
    padding: 10px 10px;
    border-color: #B4B4B4;

    @media (max-width: 900px){
        border-width: 1.2px!important;
    }
}