.social-media-section{
    .social-icon{
        padding: 1.2999999999999972rem;
        border-radius: 50%;
        box-shadow: -1px 2px 7px 4px #80808024;        
    }
    @media (max-width: 990px) {
        .icons-container{
            padding: 1rem 4rem!important;
        }
    }

    @media (max-width: 750px) {
        svg{
           width: 24px;
           height: 24px;
        }

        .social-icon{
            padding: .5rem;
        }

        .icons-container{
            padding: 1rem 1rem!important;
        }
    }
}