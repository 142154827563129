.about-landing{

    @media (min-width: 990px) {
        .who-are-we{
            line-height: 50px;
        }   
    }
}

.vision-card-sm{
    box-shadow: 0px 0px 10px 7px #f1efef;
}