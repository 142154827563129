.news-letter-section{
    .subscribe-btn{
        position: absolute;
        top: 1rem;
        right: 1.2rem;
    }
}

.news-letter-section-sm{
    .subscribe-btn{
        
        border-radius: 10px;
        padding-top: 12px!important;
        padding-bottom: 12px!important;
    }
}