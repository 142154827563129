.loan-icons-container{

    @media (max-width: 576px){
        .loan-icon{
            width: 24px;
            height: 24px;
        }

        span{
            font-size: 0.5rem;
        }
    }

    @media (max-width: 768px){
        .loan-icon{
            width: 24px;
            height: 24px;
        }

        span{
            font-size: 0.5rem;
        }
    }
}

