.discover-section{

    .man-in-globe{
        height: 90%;
    }
    
    @media (max-width: 992px) {
        .discover{
            padding-top: 1rem;
            padding-bottom: 1rem;
            background-color: rgba(196, 196, 196, 0.3);
        }  
        
        .man-in-globe{
            height: auto;
        }
    }
}

.quick-card{

    .alt{
        transition: opacity 0.3s ease;
        opacity: 0;
    }

    h6{
        font-size: 0.9rem;
        font-weight:500!important;
    }

    &:hover{
        text-align: center;
        box-shadow: 0px 3px 5px 1px #dadada;
        background-color: #ffff;
        
        .content{
            display: none;
        }
        
        .alt{
            display: block!important;
            user-select: none;
            p{
                margin: auto;
            }

            &:hover{
                opacity: 1;
            }
        }
    }
}