.mvp-section {
    .carousel-indicators{
        margin: 0;

        [data-bs-target]{
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            padding: 0;
            margin-right: 5px;
            margin-left: 5px;
            text-indent: -999px;
            cursor: pointer;
            background-color: grey;
            background-clip: padding-box;
            border: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: 0.5;
            transition: opacity 0.6s ease;   
        }

        
        [data-bs-target].active{
            background-color: #F6011B;
            opacity: 1;
        }
    }
}

// 