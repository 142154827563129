.navbar{
    @media (max-width: 990px) {
        .navbar-logo{
            width: 120px;
        }
    }

    .banking-btn:hover{
        background-color: #595959;
        border: transparent;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.655em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        color: #F6011B;
    }

    .dropdown-toggle::before {
        display: inline-block;
        margin-left: 0.655em;
        filter: blur(1px);
        right: 8px;
        z-index: -1;
        top: 14px;
        vertical-align: 0.255em;
        position: absolute;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        color: black;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        text-decoration: none;
        background-color: transparent;
        white-space:normal;
        border: 0;
    }

    .dropdown-menu{
        width: 247px;
    }
}

.offcanvas{
    .desktop-offcanvas{
        a{
            color: #282828;
            font-size: 1rem;
            font-weight: 700;
        }
    }
}