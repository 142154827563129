.contact{
    .contact-card {
        min-height: 256px;

        .card-img {
            img {
                position: absolute;
                top: -2.5rem;
            }
        }
    }
  
}