.benefit-card {
    max-width: 420px;
    border: 2px solid #520101;
    border-radius: 8px;

    .title{
        align-self: center;
    }

    .description{
        font-size: 12px;
        line-height: 1.2;
        font-weight: 600;
    }

    @media (max-width: 992px){
        .description{
            font-size: 11px;
        }
    }
}
