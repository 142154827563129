.topic{
    .active{
        
    }
}

.select-container{
    .select-item{
        :hover{
            background-color: var(--bs-gray-300);
        }
    }

    .select-items{
        display: none;
    }

    &.active{
        .select-items{
            display: block;
        }
    }

    .select-selected:after {
        position: absolute;
        content: "";
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: red transparent transparent transparent;
        // box-shadow: 0px 0px 3px 3px black;
    }
}