.home-landing {
    .bg-ovall {
        top: 0%;
        z-index: -1;
        width: 100%;
        img{
            height: 280px
        }
        
    }

    @media (min-width: 900px) {
        .bg-ovall {
            top: -80px;
            z-index: -1;
            left: -30%;
            width: 105%;
            height: 100%;
            img{
                height: auto
            }
        }
    }

    .charges {
        font-family: Nunito;
        font-weight: bold;
        font-size: 10px;
        color: #520101;
        text-shadow: 0px 3px 10px rgba(138, 34, 69, 0.87);
    }


    @media (min-width: 900px) {
        .left {
            transform: scale(0.89);
        }

        .charges {
            font-size: 13px;
        }
    }

}