.loan-and-overdraft{
    .phone-img{
        max-width: 240px;
    }

    .detail{
        text-align: justify;
        line-height: 1.7!important;
        margin-bottom: 5px!important;
    }

    .loan-card{
        img{
            &:hover{
                opacity: 0.6;
            }
        }
    }
}