
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

$dark: #282828;
$enable-cssgrid: true;

body {

    overflow-x: hidden;
    font-family: Nunito, Calibri;

    h1, h2, h3, h4, h5, h6 {
        font-family: Poppins
    }


    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1;
      }
      
      h1, .h1 {
        font-size: calc(1.775rem);
      }
      @media (min-width: 1200px) {
        h1, .h1 {
          font-size: 2.5rem;
        }
      }
      
      h2, .h2 {
        font-size: calc(1.525rem);
      }
      @media (min-width: 1200px) {
        h2, .h2 {
          font-size: 2.2rem;
        }
      }
      
      h3, .h3 {
        font-size: calc(1.3rem);
      }
      @media (min-width: 1200px) {
        h3, .h3 {
          font-size: 1.65rem;
        }
      }
      
      h4, .h4 {
        font-size: calc(1.275rem);
      }
      @media (min-width: 1200px) {
        h4, .h4 {
          font-size: 1.4rem;
        }
      }
      
      h5, .h5 {
        font-size: 1.2rem;
      }
      
      h6, .h6 {
        font-size: 1rem;
      }
      
      p, a {
            font-size: 0.72rem;
      }
      
      p{
        margin-top: 0;
        margin-bottom: 0.8rem;
      }

    a {
        font-weight: 700;
    }

    .lh-lg{
      line-height: 3!important;
    }

    .font-primary {
        font-family: Nuntino, Calibri;
    }

    .font-secondary {
        font-family: Poppins
    }

    .bg-teal {
        background-color: #FEE4E7;
    }

    .bg-teal-200 {
        background-color: #fcdce0;
    }

    .bg-teal-300 {
        background-color: #fac8ce;
    }

    .bg-teal-400 {
        background-color: #f3abb3;
    }

    .bg-teal-500 {
        background-color: #f5a4ad;
    }

    .bg-dark-teal{
        background-color: #520101;
    }

    .bg-gray{
        background-color: var(--bs-gray-100);
    }
    .bg-gray-200{
        background-color: var(--bs-gray-200);
    }
    .bg-gray-300{
        background-color: var(--bs-gray-400);
    }
    .bg-gray-400{
        background-color: var(--bs-gray-400);
    }
    .bg-gray-500{
        background-color: var(--bs-gray-500);
    }
    .bg-gray-600{
        background-color: var(--bs-gray-600);
    }
    .bg-gray-700{
        background-color: var(--bs-gray-700);
    }


    .bg-red {
        background-color: #F6011B;
    }

    .text-teal {
        color: #FEE4E7;
    }

    .text-dark-teal {
        color: #520101;
    }

    .text-red {
        color: #F6011B;
    }

    .rounded-4 {
        border-radius: 1.2999999999999998rem !important;
    }

    .fw-bold {
      font-weight: 600 !important;
  }

  img{
    image-rendering: -webkit-optimize-contrast;
  }

  @media (min-width: 992px){
    .px-lg-6 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .py-lg-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
  }
  }
}