.bills-and-more{

    .bills-and-more-landing{

        @media (min-width: 990px) {
            .detail{
                line-height: 1.7!important;
            }
        }

        .landing-img{
            width: 100%;
        }

        
    }

    .sponsor-img{
        width: 130px;
        box-shadow: -5px 2px 11px 1px #e5e5e5;
        border-radius: 50%;
    }

    @media (max-width: 990px){
        .sponsor-img{
            width: 50px;
        }
    }
    .app-btn {
        font-family: Nunito;
        font-weight: bold;
        font-size: 15px;
        border-radius: 16px;
        padding: 5px 13px;
        border-color: #B4B4B4;
    }
}